export default (axios) => ({
  list() {
    return axios.get("/admin/seo");
  },
  item(id) {
    return axios.get(`/admin/seo/${id}`);
  },
  create(data) {
    return axios.post("/admin/seo", data);
  },
  update(id, data) {
    return axios.put(`/admin/seo/${id}/update`, data);
  },
  delete(id) {
    return axios.delete(`/admin/seo/${id}/delete`);
  },
});
