import api from "../../api";
import addValueToField from "@/helpers/addValueToField";

export default {
  namespaced: true,
  state() {
    return {
      blocksList: [],
      pageData: null,
      blocksCollection: new Map(),
    };
  },
  mutations: {
    SET_BLOCKS_LIST(state, payload) {
      state.blocksList = payload;
    },
    SET_PAGE_DATA(state, payload) {
      state.pageData = payload;
    },
    ADD_NEW_BLOCK(state, { langsIso, block }) {
      langsIso.forEach((lang) => {
        const newBlock = JSON.parse(JSON.stringify(block));
        addValueToField(newBlock);
        const length = state.pageData["constructor"][lang].length;
        newBlock.position = length === 0 ? 1 : length + 1;
        state.pageData["constructor"][lang].push(newBlock);
      });
    },
    ADD_BLOCKS_COLLECTION(state, { blocks, value }) {
      state.blocksCollection.set(blocks, value);
    },
    REMOVE_BLOCK(state, { langsIso, blockIndex }) {
      langsIso.forEach((lang) => {
        state.blocksCollection.delete(
          state.pageData["constructor"][lang][blockIndex]
        );
        state.pageData["constructor"][lang].splice(blockIndex, 1);
      });
      state.blocksCollection = new Map(state.blocksCollection.entries());
    },
    ADD_NEW_BLOCK_DEEP(state, { parent, newBlock, collectionValue }) {
      const parentInfo = state.blocksCollection.get(parent);
      state.blocksCollection.forEach((info, block) => {
        if (info.place === parentInfo.place) {
          const addBlock = JSON.parse(JSON.stringify(newBlock));
          const length = block.blocks.length;
          newBlock.position = length === 0 ? 1 : length + 1;
          block.blocks.push(addBlock);
          if (collectionValue) {
            state.blocksCollection.set(addBlock, collectionValue);
          }
        }
      });
    },
    REMOVE_BLOCK_DEEP(state, { parent, blockIndex }) {
      const blockInfo = state.blocksCollection.get(parent);
      state.blocksCollection.forEach((info, block) => {
        if (info.place === blockInfo.place) {
          state.blocksCollection.delete(block.blocks[blockIndex]);
          block.blocks.splice(blockIndex, 1);
        }
      });
      state.blocksCollection = new Map(state.blocksCollection.entries());
    },
    CHANGE_POSITION_BLOCKS(state, { langsIso, blockIndex, blockIndexTo }) {
      const blocks = state.pageData["constructor"];
      langsIso.forEach((lang) => {
        if (!blocks) return;
        const tmp = blocks[lang][blockIndexTo];
        blocks[lang][blockIndexTo] = blocks[lang][blockIndex];
        blocks[lang][blockIndex] = tmp;
        blocks[lang][blockIndex].position = blockIndex + 1;
        blocks[lang][blockIndexTo].position = blockIndexTo + 1;
        state.pageData["constructor"][lang].splice();
      });
    },
    RESET(state) {
      state.pageData = null;
      state.blocksCollection = new Map();
    },
  },
  getters: {
    blocksList(state) {
      return state.blocksList.filter((block) => {
        return !["list_with_button_block"].includes(block.block_name);
      });
    },
    pageData(state) {
      return state.pageData;
    },
    langsIso(state, getters, rootState, rootGetters) {
      return rootGetters["languagesList"].map((l) => l.iso);
    },
    isBlocksFill(state, getters) {
      let count = getters.langsIso.length;
      getters.langsIso.forEach((lang) => {
        if (getters.pageData["constructor"][lang].length < 1) count--;
      });
      return count === getters.langsIso.length;
    },
  },
  actions: {
    async getBlockList({ commit }) {
      try {
        const res = await api.content.blocksList();
        res.data.forEach((block) => addValueToField(block));
        commit("SET_BLOCKS_LIST", res.data);
      } catch (e) {
        console.error(e);
      }
    },
    async getPageData({ commit, getters, rootGetters }, id) {
      try {
        commit("RESET");
        const res = await api.content.page(id);
        rootGetters["languagesList"].forEach((lang) => {
          res.data.constructor[lang.iso].forEach((block, i) => {
            const blockDefault = getters.blocksList.find(
              (b) => b.block_name === block.block_name
            );
            if (blockDefault) {
              res.data.constructor[lang.iso][i] = {
                ...JSON.parse(JSON.stringify(blockDefault)),
                ...res.data.constructor[lang.iso][i],
              };
            }
          });
        });
        commit("SET_PAGE_DATA", res.data);
      } catch (e) {
        console.error(e);
      }
    },
    addNewBlock({ commit, getters }, block) {
      commit("ADD_NEW_BLOCK", {
        langsIso: getters.langsIso,
        block,
      });
    },
    removeBlock({ commit, getters }, blockIndex) {
      commit("REMOVE_BLOCK", {
        langsIso: getters.langsIso,
        blockIndex,
      });
    },
    toPositionBlock({ commit, getters }, { direction, blockIndex }) {
      let blockIndexTo = -1;
      if (direction === "up") blockIndexTo = blockIndex - 1;
      if (direction === "down") blockIndexTo = blockIndex + 1;
      if (
        blockIndexTo < 0 ||
        blockIndexTo > getters.pageData["constructor"].ru.length - 1
      ) {
        return;
      }
      commit("CHANGE_POSITION_BLOCKS", {
        langsIso: getters.langsIso,
        blockIndex,
        blockIndexTo,
      });
    },
  },
};
