export default (axios) => ({
  list(subjectId) {
    return axios.get(`/admin/subjects/${subjectId}/specs`);
  },
  create(subjectId, data) {
    return axios.post(`/admin/subjects/${subjectId}/specs`, data);
  },
  update(subjectId, specId, data) {
    return axios.post(`/admin/subjects/${subjectId}/specs/${specId}`, data);
  },
  delete(specId) {
    return axios.delete(`/admin/subjects/specs/${specId}`);
  },
});
