import axios from "axios";
import store from "@/store";

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_URL_API,
});
axiosInstance.interceptors.request.use((config) => {
  if (store.getters["admin/isAuth"]) {
    config.headers.Authorization = `Bearer ${store.getters["admin/token"]}`;
  }
  return config;
});
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) store.dispatch("admin/logout");
    return Promise.reject(error);
  }
);
export default axiosInstance;
