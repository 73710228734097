import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import ru from "vuetify/lib/locale/ru";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { ru },
    current: "ru",
  },
  theme: {
    themes: {
      light: {
        primary: "#0b1b46",
        secondary: "#8b91dd",
        accent: "#fb236a",
        error: "#d50606",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        "bg-light": "#f2f2f8",
        bg: "#96a0bd",
      },
    },
  },
});
