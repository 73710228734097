export default (axios) => ({
  clearAll() {
    return axios.post("admin/cache-clear/all");
  },
  clearNuxt() {
    return axios.post("admin/cache-clear/nuxt");
  },
  clearApi() {
    return axios.post("admin/cache-clear/config");
  },
});
