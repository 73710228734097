export default (axios) => ({
  fetchViolations(params) {
    return axios.get("/admin/violations", { params });
  },
  changeViolationStatus(id, params) {
    return axios.put(`/admin/violations/${id}`, params);
  },
  deleteViolation(id) {
    return axios.delete(`/admin/violations/${id}`);
  },
  fetchReports(params) {
    return axios.get("/admin/reports", { params });
  },
  changeReportStatus(id, params) {
    return axios.put(`/admin/reports/${id}`, params);
  },
  deleteReport(id) {
    return axios.delete(`/admin/reports/${id}`);
  },
  fetchFeedbacks(params) {
    return axios.get("/admin/user-feedbacks", { params });
  },
  deleteFeedback(id) {
    return axios.delete(`/admin/user-feedbacks/${id}`);
  },
  updateFeedback(feedbackId, params) {
    return axios.patch(`/admin/user-feedbacks/${feedbackId}`, params);
  },
  fetchReportsPageCounters() {
    return axios.get(`/admin/reports/counters`);
  },
});
