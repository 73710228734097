import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home"),
    redirect: { name: "Users" },
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        component: () => import("@/views/Dashboard"),
      },
      {
        path: "subjects",
        name: "Subjects",
        component: () => import("@/views/Subjects"),
      },
      {
        path: "users",
        name: "Users",
        component: () => import("@/views/Users"),
      },
      {
        path: "notifications",
        name: "Notifications",
        component: () => import("@/views/Notifications"),
      },
      {
        path: "verifications",
        name: "DocumentVerifications",
        component: () => import("@/views/DocumentVerifications"),
      },
      {
        path: "articles",
        name: "Articles",
        component: () => import("@/views/Articles"),
      },
      {
        path: "feedback",
        name: "Feedback",
        component: () => import("@/views/Feedback"),
      },
      {
        path: "settings",
        name: "Settings",
        component: () => import("@/views/Settings"),
      },
      {
        path: "content",
        name: "Content",
        redirect: { name: "ContentPages" },
        component: () => import("@/views/Content"),
        children: [
          {
            path: "pages",
            name: "ContentPages",
            component: () => import("@/components/Content/PagesTable"),
          },
          {
            path: "pages/:id",
            name: "ContentPagesEdit",
            props: true,
            component: () => import("@/components/Content/PageEdit"),
          },
          {
            path: "menu",
            name: "ContentMenuEdit",
            component: () => import("@/components/Content/MenuEdit"),
          },
          {
            path: "seo",
            name: "Seo",
            component: () => import("@/components/Content/Seo/index.vue"),
            children: [
              {
                path: "links",
                name: "SeoLinks",
                component: () =>
                  import("@/components/Content/Seo/SeoLinks.vue"),
              },
              {
                path: "advantages",
                name: "SeoAdvantagesBlock",
                component: () =>
                  import("@/components/Content/Seo/SeoAdvantagesBlock.vue"),
              },
            ],
          },
          {
            path: "faq",
            name: "ContentFaq",
            component: () => import("@/components/Content/Faq"),
          },
        ],
      },
      {
        path: "referrals",
        name: "Referrals",
        component: () => import("@/views/Referrals"),
      },
      {
        path: "partners",
        name: "Partners",
        component: () => import("@/views/Partners"),
      },
      {
        path: "partners-request",
        name: "PartnersRequest",
        component: () => import("@/views/PartnersRequest"),
      },
      {
        path: "reports",
        name: "Reports",
        component: () => import("@/views/Reports"),
      },
      {
        path: "lessons",
        name: "Lessons",
        component: () => import("@/views/Lessons"),
      },
      {
        path: "black-list",
        name: "BlackList",
        component: () => import("@/views/BlackList.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login"),
  },
  {
    path: "/public/dashboard",
    name: "PublicDashboard",
    component: () => import("@/views/PublicDashboard"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
