export default (axios) => ({
  referrals(params) {
    return axios.get("/admin/referrals", {
      params: params,
    });
  },
  referralsDelete(id) {
    return axios.delete(`/admin/referrals/${id}/delete`);
  },
  invites() {
    return axios.get("/admin/referral-invites");
  },
  invitesDelete(id) {
    return axios.delete(`/admin/referral-invites/${id}/delete`);
  },
});
