export default (axios) => ({
  list(params) {
    return axios.get("/admin/lessons", { params });
  },
  individualLessonStatistic(params) {
    return axios.get("/admin/dashboard/individual-lessons/statistic", {
      params,
    });
  },
  groupLessonStatistic(params) {
    return axios.get("/admin/dashboard/group-lessons/statistic", {
      params,
    });
  },
  courseLessonStatistic(params) {
    return axios.get("/admin/dashboard/course-lessons/statistic", {
      params,
    });
  },
  individualLessonsStatistics(params) {
    return axios.get("/admin/individual-lessons", {
      params,
    });
  },
  groupLessonsStatistics(params) {
    return axios.get("/admin/group-lessons", {
      params,
    });
  },
  courseLessonsStatistics(params) {
    return axios.get("/admin/course-lessons", {
      params,
    });
  },
  coursesStatistics(params) {
    return axios.get("/admin/courses", {
      params,
    });
  },
  singleIndividualLessonStatistics(lessonId) {
    return axios.get(`/admin/individual-lessons/${lessonId}`);
  },
  singleGroupLessonStatistics(lessonId) {
    return axios.get(`/admin/group-lessons/${lessonId}`);
  },
  singleCourseLessonStatistics(lessonId) {
    return axios.get(`/admin/course-lessons/${lessonId}`);
  },
  courseStatistics(courseId) {
    return axios.get(`/admin/courses/${courseId}`);
  },
});
