import api from "../../api";

export default {
  namespaced: true,
  state() {
    return {
      countriesAll: [],
      roles: [],
      currencies: [],
    };
  },
  getters: {
    countriesAll(state) {
      return state.countriesAll;
    },
    roles(state) {
      return state.roles.filter((r) => r.name !== "affiliate");
    },
    currencies(state) {
      return state.currencies;
    },
  },
  mutations: {
    SET_COUNTRIES_ALL(state, payload) {
      state.countriesAll = payload;
    },
    SET_ROLES(state, payload) {
      state.roles = payload;
    },
    SET_CURRENCIES(state, payload) {
      state.currencies = payload;
    },
  },
  actions: {
    async getCountriesAll({ commit }) {
      try {
        const req = await api.publicInfo.countriesAll();
        if (req.data) {
          commit("SET_COUNTRIES_ALL", req.data);
        }
      } catch (e) {
        console.error("getCountriesAll", e);
      }
    },
    async getRoles({ commit }) {
      try {
        const req = await api.publicInfo.roles();
        if (req.data) {
          commit("SET_ROLES", req.data);
        }
      } catch (e) {
        console.error("getRoles", e);
      }
    },
    async getCurrencies({ commit }) {
      try {
        const req = await api.publicInfo.currencies();
        if (req.data) {
          const data = [];
          for (const k in req.data) {
            data.push(req.data[k]);
          }
          commit("SET_CURRENCIES", data);
        }
      } catch (e) {
        console.error("getCurrencies", e);
      }
    },
    getAll({ dispatch }) {
      dispatch("getCountriesAll");
      dispatch("getRoles");
    },
  },
};
