import axios from "../axios/";
import admin from "./admin";
import subjects from "./subjects";
import users from "./users";
import articles from "./articles";
import documents from "./documents";
import publicInfo from "./publicInfo";
import specs from "./specs";
import accessIp from "./settings/AccessIp";
import publicDashboard from "./publicDashboard";
import feedbacks from "./feedbacks";
import content from "./content";
import menu from "./menu";
import seo from "./seo";
import faq from "./faq";
import dashboard from "./dashboard";
import referrals from "./referrals";
import partners from "./partners";
import reports from "./reports";
import lessons from "./lessons";
import cache from "./settings/Cache";
import blackList from "./blackList";
import notifications from "./notifications";
import banners from "./banners";
import seoBlocks from "./seoBlocks";

export default {
  user: admin(axios),
  subjects: subjects(axios),
  users: users(axios),
  articles: articles(axios),
  documents: documents(axios),
  publicInfo: publicInfo(axios),
  specs: specs(axios),
  settings: {
    accessIp: accessIp(axios),
    cache: cache(axios),
  },
  publicDashboard: publicDashboard(axios),
  feedbacks: feedbacks(axios),
  content: content(axios),
  menu: menu(axios),
  seo: seo(axios),
  seoBlocks: seoBlocks(axios),
  faq: faq(axios),
  dashboard: dashboard(axios),
  referrals: referrals(axios),
  partners: partners(axios),
  reports: reports(axios),
  lessons: lessons(axios),
  blackList: blackList(axios),
  notifications: notifications(axios),
  banners: banners(axios),
};
