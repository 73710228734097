export default (axios) => ({
  tokenIp() {
    const instance = axios.create();
    return instance.get("admin/access-token", {
      transformRequest: (data, headers) => {
        delete headers.common["Authorization"];
        return data;
      },
    });
  },
});
