export default (axios) => ({
  getItems(params) {
    return axios.get(`/admin/test-notifications`, { params: params });
  },
  deleteItem(id) {
    return axios.delete(`/admin/test-notifications/${id}`);
  },
  deleteAllItem() {
    return axios.delete(`/admin/test-notifications`);
  },
});
