export default (axios) => ({
  partnerLinks(params) {
    return axios.get("/admin/partner-links", { params });
  },
  affiliatesApplications(params) {
    return axios.get("/admin/affiliates/applications", { params });
  },
  affiliatesApplicationsUpdate(id, data) {
    return axios.put(`/admin/affiliates/applications/${id}`, data);
  },
  affiliatesApplicationsDelete(id) {
    return axios.delete(`/admin/affiliates/applications/${id}`);
  },
  affiliatesApplicationsCount() {
    return axios.get("/admin/affiliates/applications/count");
  },
});
