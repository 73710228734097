<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  data() {
    return {};
  },
};
</script>

<style lang="scss">
html {
  overflow: auto !important;
}
// style for scrollbar
* {
  scrollbar-width: thin;
  scrollbar-color: #f2f2f8 #96a0bd;

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f2f2f8;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #96a0bd;
    border-radius: 3px;
  }
}
</style>
