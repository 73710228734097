export default (axios) => ({
  subjectsList(params) {
    return axios.get("/admin/subjects", { params: params });
  },
  subject(id) {
    return axios.get(`/admin/subjects/${id}`);
  },
  createSubject(data) {
    return axios.post("/admin/subjects", data);
  },
  updateSubject(id, data) {
    return axios.post(`/admin/subjects/${id}`, data);
  },
  deleteSubject(id) {
    return axios.delete(`/admin/subjects/${id}`);
  },
  togglePopular(id, status) {
    return axios.patch(`/admin/subjects/${id}/toggle-popular`, { status });
  },
});
