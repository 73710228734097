export default (axios) => ({
  getSeoBlocks() {
    return axios.get("/admin/site-blocks");
  },
  deleteSeoBlock(siteBlockId) {
    return axios.delete(`/admin/site-blocks/${siteBlockId}/delete`);
  },
  getSeoBlock(siteBlockId) {
    return axios.get(`/admin/site-blocks/${siteBlockId}`);
  },
  createSeoBlock(data) {
    return axios.post(`/admin/site-blocks`, data);
  },
  updateSeoBlock(data) {
    return axios.post(`/admin/site-blocks/${data.id}/update`, data);
  },
});
