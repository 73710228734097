export default (axios) => ({
  countriesAll() {
    return axios.get("admin/geo/countries-all");
  },
  roles() {
    return axios.get("admin/role/roles-all");
  },
  currencies() {
    return axios.get("/public/currencies");
  },
});
