import api from "../../api";
import router from "@/router";

export default {
  namespaced: true,
  state() {
    return {
      admin: {
        token: localStorage.getItem("tema_a_token") || null,
        email: localStorage.getItem("tema_a_email") || null,
      },
    };
  },
  getters: {
    isAuth(state) {
      return !!state.admin.token;
    },
    adminEmail(state) {
      return state.admin.email;
    },
    token(state) {
      return state.admin.token;
    },
  },
  mutations: {
    SET_ADMIN(state, admin) {
      state.admin.token = admin.token;
      state.admin.email = admin.email;
      localStorage.setItem("tema_a_token", admin.token);
      localStorage.setItem("tema_a_email", admin.email);
    },
  },
  actions: {
    async login({ commit }, data) {
      try {
        const req = await api.user.login(data);
        if (req.data) {
          commit("SET_ADMIN", {
            token: req.data.data.token,
            email: data.email,
          });
        }
        router.push({ name: "Home" });
      } catch (e) {
        console.error(e);
      }
    },
    async logout({ commit }) {
      commit("SET_ADMIN", {
        token: null,
        email: null,
      });
      router.push({ name: "Login" });
    },
  },
};
