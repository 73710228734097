export default (axios) => ({
  list() {
    return axios.get("/admin/faq");
  },
  create(data) {
    return axios.post("/admin/faq", data);
  },
  update(id, data) {
    return axios.patch(`/admin/faq/${id}/update`, data);
  },
  delete(id) {
    return axios.delete(`/admin/faq/${id}/delete`);
  },
});
