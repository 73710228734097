export default (axios) => ({
  categories(params) {
    return axios.get("/admin/articles/categories", { params: params });
  },
  categoriesList() {
    return axios.get("/admin/articles/categories/list");
  },
  category(id) {
    return axios.get(`/admin/articles/categories/${id}`);
  },
  createCategory(data) {
    return axios.post("/admin/articles/categories", data);
  },
  updateCategory(id, data) {
    return axios.put(`/admin/articles/categories/${id}`, data);
  },
  deleteCategory(id) {
    return axios.delete(`/admin/articles/categories/${id}`);
  },
  tags(params) {
    return axios.get("/admin/articles/tags", { params: params });
  },
  tagsList() {
    return axios.get("/admin/articles/tags/list");
  },
  tag(id) {
    return axios.get(`/admin/articles/tags/${id}`);
  },
  createTag(data) {
    return axios.post("/admin/articles/tags", data);
  },
  updateTag(id, data) {
    return axios.put(`/admin/articles/tags/${id}`, data);
  },
  deleteTag(id) {
    return axios.delete(`/admin/articles/tags/${id}`);
  },
  articles(params) {
    return axios.get("/admin/articles", { params: params });
  },
  article(id) {
    return axios.get(`/admin/articles/${id}`);
  },
  createArticle(data) {
    return axios.post("/admin/articles", data);
  },
  updateArticle(id, data) {
    return axios.post(`/admin/articles/${id}`, data);
  },
  deleteArticle(id) {
    return axios.delete(`/admin/articles/${id}`);
  },
});
