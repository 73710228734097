export default (axios) => ({
  list(params) {
    return axios.get("admin/feedbacks", { params: params });
  },
  status(id, status) {
    return axios.put(`admin/feedbacks/${id}`, { status: status });
  },
  deleteItem(id) {
    return axios.delete(`admin/feedbacks/${id}`);
  },
  newCount() {
    return axios.get("admin/feedbacks", {
      params: {
        itemsPerPage: 1,
        filterStatus: "notProcessed",
      },
    });
  },
  emails() {
    return axios.get("admin/feedback/emails");
  },
  createEmails(data) {
    return axios.post("admin/feedback/emails", data);
  },
  deleteEmail(id) {
    return axios.delete(`admin/feedback/emails/${id}`);
  },
});
