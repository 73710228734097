export default (axios) => ({
  getItems() {
    return axios.get("/admin/banners");
  },
  createItem(data) {
    return axios.post("/admin/banners", data);
  },
  updateItem(id, data) {
    return axios.post(`/admin/banners/${id}/update`, data);
  },
  deleteItem(id) {
    return axios.delete(`/admin/banners/${id}/delete`);
  },
});
