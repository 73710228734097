export default (axios) => ({
  blocksList() {
    return axios.get("/admin/pages/blocks");
  },
  pagesList() {
    return axios.get("/admin/pages");
  },
  page(id) {
    return axios.get(`/admin/pages/${id}`);
  },
  pageAdd(data) {
    return axios.post("/admin/pages", data);
  },
  pageUpdate(id, data) {
    return axios.put(`/admin/pages/${id}/update`, data);
  },
  pageVisibility(id, visibility) {
    return axios.patch(`/admin/pages/${id}/toggle-visibility`, { visibility });
  },
  pageDelete(id) {
    return axios.delete(`/admin/pages/${id}/delete`);
  },
  filesUpload(id, data) {
    return axios.post(`/admin/pages/${id}/files`, data);
  },
  filesDelete(id, data) {
    return axios.delete(`/admin/pages/${id}/files`, { data: data });
  },
});
