export default (axios) => ({
  menu() {
    return axios.get("/admin/menu");
  },
  createMenu(data) {
    return axios.post("/admin/menu", data);
  },
  updateMenu(id, data) {
    return axios.put(`/admin/menu/${id}/update`, data);
  },
  moveMenu(id, action) {
    return axios.post(`/admin/menu/${id}/move`, { action });
  },
  removeMenu(id) {
    return axios.delete(`/admin/menu/${id}/delete`);
  },
  createSettings() {
    return axios.get("/admin/menu/create-settings");
  },
});
