export default (axios) => ({
  itemsList(params) {
    return axios.get("/admin/users/documents", { params: params });
  },
  item(id) {
    return axios.get(`/admin/users/documents/${id}`);
  },
  updateItem(id, data) {
    return axios.put(`/admin/users/documents/${id}`, data);
  },
});
