export default (axios) => ({
  getUsers(params) {
    return axios.get(`/admin/users`, { params: params });
  },
  getUser(id) {
    return axios.get(`/admin/users/${id}`);
  },
  sendUser(data) {
    return axios.post("/admin/users", data);
  },
  editUser(id, item) {
    return axios.put(`/admin/users/${id}`, item);
  },
  deleteUser(id) {
    return axios.delete(`/admin/users/${id}`);
  },
  phoneVerify(id) {
    return axios.put(`/admin/users/${id}/verify-phone`);
  },
  getSchedule(id, params) {
    return axios.get(`/admin/users/${id}/lessons`, { params });
  },
  download(params) {
    return axios.get(`/admin/users/download`, { params, responseType: "blob" });
  },
  setCurrency(id, currency) {
    return axios.put(`/admin/users/${id}/change-currency`, { currency });
  },
  setTestStatus(id, status) {
    return axios.put(`/admin/users/${id}/set-test-status`, status);
  },
});
