import Vue from "vue";
import Vuex from "vuex";

import admin from "@/store/admin";
import publicInfo from "@/store/publicInfo";
import pageEdit from "@/store/pageEdit";
import api from "../../api";

Vue.use(Vuex);

export default new Vuex.Store({
  state() {
    return {
      snackbar: {
        show: false,
        timeout: 3000,
        color: "success",
        msg: "",
        text: "#fff",
      },
      updateTime: 1000 * 60 * 2,
      languages: [
        {
          name: "RU",
          iso: "ru",
          icon: "ru",
        },
        {
          name: "UA",
          iso: "uk",
          icon: "ua",
        },
        {
          name: "EN",
          iso: "en",
          icon: "us",
        },
        {
          name: "PL",
          iso: "pl",
          icon: "pl",
        },
      ],
      docCount: "",
      feedbacksCount: "",
      affiliatesApplicationsCount: "",
      reportsPageCounter: null,
    };
  },
  mutations: {
    SET_SNACKBAR(state, { msg, color, show }) {
      state.snackbar.msg = msg;
      state.snackbar.color = color;
      state.snackbar.show = show;
    },
    SET_DOC_COUNT(state, payload) {
      state.docCount = payload;
    },
    SET_FEEDBACKS_COUNT(state, payload) {
      state.feedbacksCount = payload;
    },
    SET_AFFILIATES_APPLICATIONS_COUNT(state, payload) {
      state.affiliatesApplicationsCount = payload;
    },
    SET_REPORTS_PAGE_COUNTERS(state, payload) {
      state.reportsPageCounter = payload;
    },
  },
  getters: {
    snackbarOptions(state) {
      return state.snackbar;
    },
    languagesList(state) {
      return state.languages;
    },
    docCount(state) {
      return state.docCount;
    },
    updateTime(state) {
      return state.updateTime;
    },
    feedbacksCount(state) {
      return state.feedbacksCount;
    },
    affiliatesApplicationsNew(state) {
      return state.affiliatesApplicationsCount?.new;
    },
    reportsPageCounter(state) {
      return state.reportsPageCounter;
    },
  },
  actions: {
    showSnackbar({ commit }, payload) {
      commit("SET_SNACKBAR", payload);
    },
    closeSnackbar({ commit }) {
      commit("SET_SNACKBAR", { show: false });
    },
    async getDocsCount({ commit }) {
      try {
        const req = await api.documents.itemsList({ status: "new" });
        if (req) {
          commit("SET_DOC_COUNT", req.data?.total || "");
        }
      } catch (e) {
        console.error(e);
      }
    },
    async getFeedbacksCount({ commit }) {
      try {
        const res = await api.feedbacks.newCount();
        if (res) {
          commit("SET_FEEDBACKS_COUNT", res.data?.total || "");
        }
      } catch (e) {
        console.error(e);
      }
    },
    async getAffiliatesApplicationsCount({ commit }) {
      try {
        const { data } = await api.partners.affiliatesApplicationsCount();
        commit("SET_AFFILIATES_APPLICATIONS_COUNT", data);
      } catch (e) {
        console.error(e);
      }
    },
    async setupReportsPageCounter({ commit }) {
      try {
        const response = await api.reports.fetchReportsPageCounters();
        commit("SET_REPORTS_PAGE_COUNTERS", response.data);
      } catch (e) {
        console.error(e);
      }
    },
  },
  modules: {
    admin,
    publicInfo,
    pageEdit,
  },
});
